import React, {useEffect, useState, useRef} from 'react';
import {Alert, Button, Card, Container, Form} from 'react-bootstrap';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

let socket;

const MAX_INDEX = 4;

function App() {
  const output = useRef("");
  const currentIndex = useRef(0);
  const [isConnected, setIsConnected] = useState(false);
  const [outputHTML, setOutputHTML] = useState("");
  const [keepText, setKeepText] = useState(true);
  const [inputTxt, setInputTxt] = useState("");
  const [displayInput, setDisplayInput] = useState("");
  const [running, setRunning] = useState(false);
  const runningRef = useRef(false);

  useEffect(() => {

    const connectSocket = () => {
      socket = new WebSocket('ws://74.101.148.179:6789');

      socket.addEventListener('open', function (event) {
        setIsConnected(true);
      });

      socket.addEventListener('close', () => {
        setIsConnected(false);
        connectSocket();
      });

      socket.addEventListener('message', function (event) {
        const json = JSON.parse(event.data);
        if (json.type === "users") {

          const newOutput = output.current + json.value;
          output.current = newOutput;
          setOutputHTML(newOutput);

          if (currentIndex.current < MAX_INDEX && runningRef.current) {
            socket.send(JSON.stringify({text: newOutput}));

            currentIndex.current++;

          } else {
            currentIndex.current = 0;
            setRunning(false);
            runningRef.current = false;
          }
        }
      });
    };

    connectSocket();

  }, []);

  return (
    <div style={{padding: 12}}>
      <Container style={{backgroundColor: "#FFF", borderRadius: 8, padding: "12px 18px"}}>
        <h3>GPT-2 Neural Net Text Predictor<br/><h6>by Steve Dakh</h6></h3>

        <Form>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Enter Starting Text for AI</Form.Label>
            <Form.Control
              as="textarea"
              rows="5"
              value={inputTxt}
              onChange={event => setInputTxt(event.target.value)}
            />
          </Form.Group>
        </Form>

        <div>
          <Button
            variant={running ? "danger" : "primary"}
            onClick={() => {
              if (!inputTxt) return;

              if (!running) {
                setRunning(true);
                runningRef.current = true;
                setOutputHTML("");
                setDisplayInput(inputTxt);
                output.current = "";
                socket.send(JSON.stringify({text: inputTxt}));
              } else {
                setRunning(false);
                runningRef.current = false;
              }
            }}
          >
            {!running ? "Go" : "Stop!"}
          </Button>
          &nbsp;

          <Button
            variant={"warning"}
            onClick={() => {
              if (!inputTxt) return;

              currentIndex.current = 5;
              setRunning(true);
              runningRef.current = true;
              setOutputHTML("");
              let fullText = inputTxt;

              if (keepText)
                fullText += outputHTML;

              setInputTxt(fullText);
              setDisplayInput(fullText);

              output.current = "";
              socket.send(JSON.stringify({text: fullText}));
            }}
          >
            Step Through
          </Button>

          &nbsp;

          <Button
            variant={"secondary"}
            onClick={() => {
              setInputTxt("");
              setDisplayInput("");
              setOutputHTML("");
              output.current = "";
            }}
          >
            Clear
          </Button>

          &nbsp;

          <Form.Check
            inline
            type={"checkbox"}
            id={`inline-checkbox`}
            label={`Keep text on step`}
            checked={keepText}
            onChange={(event) => setKeepText(event.target.checked)}
          />
        </div>

        <br/>
        {displayInput ? (
          <div>
            <Card>
              <Card.Header>Result:</Card.Header>
              <Card.Body>
                {<span
                  style={{fontWeight: "bold"}}
                  dangerouslySetInnerHTML={{__html: displayInput.replace("<|endoftext|>", "").replace(/\n/g, "<br />")}}
                />}
                {outputHTML.replace("<|endoftext|>", "").split("\n").map((item, key) => {
                  return <span key={key}>{item}<br/></span>
                })}
              </Card.Body>
            </Card>
            <br/>
          </div>
        ) : null}

        <h4>Instructions for use:</h4>
        <p>Enter some starting content. Press <b>Go</b> and the neural net will generate 5 samples of new original
          content. The Step Through button will get only one sample at a time.</p>


        <Alert variant={isConnected ? "success" : "danger"}>
          AI Status: {isConnected ? "Online" : "Disconnected. Trying to connect..."}
        </Alert>
      </Container>
    </div>
  );
}

export default App;
